import clsx from 'clsx';
import React, { useState, useEffect, useCallback } from 'react';

import {
  EScrollIndicatorBackground,
  EScrollIndicatorSize,
  EScrollIndicatorTheme,
} from './constants';

export type TScrollIndicatorProps = {
  background?: EScrollIndicatorBackground;
  rounded?: boolean;
  theme?: EScrollIndicatorTheme;
  size?: EScrollIndicatorSize;
  showOnlyInProgress?: boolean;
};

const ScrollIndicator: React.FC<TScrollIndicatorProps> = ({
  rounded = true,
  theme = EScrollIndicatorTheme.Brand,
  size = EScrollIndicatorSize.Medium,
  background = EScrollIndicatorBackground.Transparent,
  showOnlyInProgress = false,
}) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = useCallback(() => {
    const maxScrollY =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollY = window.scrollY;
    const percentage = (scrollY / maxScrollY) * 100;
    setScrollPercentage(percentage);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div
      className={clsx('absolute left-0 right-0 top-0 -z-10', {
        'rounded-xl 2xl:rounded-3xl': rounded,
        'bottom-0': size === EScrollIndicatorSize.Small,
        'bottom-0 overflow-hidden': size === EScrollIndicatorSize.Medium,
        hidden: showOnlyInProgress && scrollPercentage === 0,
      })}
    >
      {/* Indicator's background */}
      <div
        className={clsx('absolute left-0 w-full', {
          'rounded-full': rounded,
          '-bottom-0.5 h-0.5': size === EScrollIndicatorSize.Small,
          'bottom-0 h-[0.188rem] 2xl:h-1': size === EScrollIndicatorSize.Medium,
          'bg-[rgba(236,237,246,1)]':
            background === EScrollIndicatorBackground.Grey,
        })}
      />
      {/* Indicator */}
      <div
        className={clsx('absolute left-0', {
          'rounded-full': rounded,
          '-bottom-0.5 h-0.5': size === EScrollIndicatorSize.Small,
          'bottom-0 h-[0.188rem] 2xl:h-1': size === EScrollIndicatorSize.Medium,
          'bg-brand-500/90': theme === EScrollIndicatorTheme.Brand,
          'bg-brand-500': theme === EScrollIndicatorTheme.LightBrand,
        })}
        style={{ width: `${scrollPercentage}%` }}
      />
    </div>
  );
};

export default ScrollIndicator;
